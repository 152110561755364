@import "dyl-components/config.scss";

.RingGroup_Modal {
    .displayOnOffClass {
        top: 2em;
    }
    .Modal__content .callQueue .extension-container {
        flex-direction: column !important;
        width: 41%;
        margin: 0px;
    }
    .callQueue .no_answer_destinations_nested_dropdown {
        max-width: 25%;
    }
    .select-control-segment {
        max-height: 20em;
        overflow: auto;
    }
    .RecordsTable
        div.RecordsTable__TableSegment
        div.TableSegment__OverflowContainer {
        overflow-x: visible;
    }
    .ToggableDropdown_Options {
        width: 100%;
    }
    .extension-container > div.fields > div {
        width: 100%;
    }
    .extension-container > div.fields > div > div {
        width: 100%;
    }
  
}
