@import 'dyl-components/config.scss';

.ExternalNumberModal__form {
    .ExternalNumberModal__controllerContainer {
        display: flex;
        gap: 25px;

        .field {
            flex: 1
        }
    }

    .ExternalNumberModal__callerIDContainer {
        display: flex;
        gap: 25px;
        align-items: center;

        .field {
            flex: 1
        }
    }

    .ExternalNumberModal__callerIDCheckboxContainer {
        display: flex;
        gap: 25px;
        margin-bottom: 20px;

        .field {
            flex: 1
        }
    }

    .ExternalNumberModal__checkboxContainer {
        display: flex;
        gap: 25px;
    }
}