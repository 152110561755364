@import "dyl-components/config.scss";

.UserTableDropdown {
    div.SearchBarSegmentGroup {
        margin-bottom: 0px;
        border: 1px solid #777777;

        div.ui.segment {
            padding: 0px;
        }

        div.SearchBarSegment {
            &__SelectedUsersGrid {
                max-height: 140px;
                margin-top: 0px;
                margin-bottom: 0px;
                overflow-y: auto;

                div.column {
                    padding: 0.3rem;
                }

                .SelectedUserSegment {
                    padding-top: 1.3rem;
                    padding-bottom: 1.3rem;

                    &__name {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: block;
                        white-space: nowrap;
                    }

                    &__email {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        display: block;
                        white-space: nowrap;
                    }
                }
            }

            &__SearchInput {
                width: 100%;

                div.ui.input {
                    input {
                        border: none !important;
                    }
                }
            }
        }

        div.ResultsSegment {
            div.ResultsGrid {
                max-height: 250px;
                margin-top: 0px;
                margin-bottom: 0px;
                overflow-y: auto;

                div.ResultsGrid__HeaderRow {
                    font-weight: bold;
                    padding-top: 7px;
                    padding-bottom: 7px;
                }
            }
        }
    }

    &__ErrorMessage {
        color: $primary-error-1;
    }
}

.CampaignUsersList {
    div.ui.header.CampaignUsersList__PageHeader {
        margin-top: 0.8em;
    }

    .RecordsTable div.RecordsTable__TableSegment div.TableSegment__OverflowContainer {
        overflow-x: visible;
    }

    .ui.segments.TableWithHeader {
        border-style: none;
        box-shadow: none;

        .ui.segment.TableWithHeader__header {
            background-color: $white;
            border-radius: 0px;
            padding: 0px 0px 20px;

            .UsersToolbar {
                &__NewUsersButton.ui.button {
                    margin-left: auto;
                    color: $white;
                    background-color: $primary-table-6;
                }
            }
        }
    }

    .ui.segment.TableWithHeader__table {
        border-style: none;
        border-radius: 0px;

        .ui.segments {
            border-style: none;
            border-radius: 0px;
            box-shadow: none;

            .ui.segment {
                border-style: none;
                border-radius: 0px;

                .ui.table {
                    margin-bottom: 0px;
                }
            }

            .ui.segment.right {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .icon.fas.fa-copy {
        color: $primary-table-6;
    }

    .CampaignUsersTable {
        &__SmallFixedWidth {
            min-width: 120px;
        }

        &__MediumFixedWidth {
            min-width: 175px;
        }

        &__LargeFixedWidth {
            min-width: 300px;
        }
    }

    .Person {
        max-width: 250px;
    }
}
